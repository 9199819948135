<template>
    <v-card flat>

        <v-card-text class="mb-4">
            <div class="pa-3">
                About company
            </div>
            
            <v-card-text class="">
                {{ company.about }}
            </v-card-text>
        </v-card-text>
        <v-card-text class="mb-4">
            <div class="pa-3">
                Activity sector
            </div>
            <v-card-text class="">
                {{ company.about }}
            </v-card-text>
        </v-card-text>
        <v-card-text class="rounded-lg" flat>
            <v-list>
                <v-list-item>
                    <v-list-item-icon>
                        <v-icon color="indigo">
                            {{ icons.mdiPhone }}
                        </v-icon>
                    </v-list-item-icon>
                    <v-list-item-content>
                        <v-list-item-title>{{ company.phone }}</v-list-item-title>
                        <v-list-item-subtitle>Phone number</v-list-item-subtitle>
                    </v-list-item-content>
                </v-list-item>
                <v-list-item>
                    <v-list-item-icon>
                        <v-icon color="indigo">
                            {{ icons.mdiEmail }}
                        </v-icon>
                    </v-list-item-icon>

                    <v-list-item-content>
                        <v-list-item-title>{{ company.email }}</v-list-item-title>
                        <v-list-item-subtitle>Email adress</v-list-item-subtitle>
                    </v-list-item-content>
                </v-list-item>

                

                <v-list-item>
                    <v-list-item-icon>
                        <v-icon color="indigo">
                            {{ icons.mdiWeb }}
                        </v-icon>
                    </v-list-item-icon>

                    <v-list-item-content>
                        <v-list-item-title>{{ company.website }}</v-list-item-title>
                        <v-list-item-subtitle>Website</v-list-item-subtitle>
                    </v-list-item-content>
                </v-list-item>
            </v-list>
        </v-card-text>
    </v-card>
</template>

<script>

import { ref } from '@vue/composition-api'
import {
    mdiPhone, mdiMessageText, mdiMapMarker, mdiEmail, mdiWeb,
    mdiKeyboardBackspace, mdiTrashCan, mdiPrinter,
    mdiCalendarRange, mdiMapMarkerOutline, 
} from '@mdi/js'

export default {
    data() {
        return {
            company: this.$store.getters.getCompany
        }
    },
    // beforeMount() {
    //     console.log(this.company)
    // },
    setup() {
        const icons = {
            mdiPhone, mdiMessageText, mdiMapMarker, mdiEmail, mdiWeb,
            mdiKeyboardBackspace, mdiTrashCan, mdiPrinter,
            mdiCalendarRange, mdiMapMarkerOutline
        }
        const companyname = ref('')
        const email = ref('')
        return {
            icons,
            companyname,
            email
        }
    }
}
</script>

<style lang="scss" scoped>
.request-read-content {
    border: solid 1px;
    margin-top: 2rem;
}

.avatar-center {
    top: -3rem;
    left: 3rem;
    border: 3px solid white;
    position: absolute;
}
</style>