<template>
    
    <v-card  elevation="0">
        <v-card-text class="">
            -- <span class="accent--text">{{header.subtitle}}..</span>
        </v-card-text>
        <div class="secondary--text">
            <h1 class="secondary--text">{{header.title}}</h1>
        </div>
        <v-card-text class="">
            <v-btn icon small color="secondary" @click.prevent="slidePrev">
                <v-icon size="">{{ icons.mdiArrowLeftThinCircleOutline }}
                </v-icon>
            </v-btn>
            <v-btn icon small color="secondary" @click.prevent="slideNext">
                <v-icon size="">{{ icons.mdiArrowRightThinCircleOutline }}
                </v-icon>
            </v-btn>
        </v-card-text>
    </v-card>
</template>

<script>

// eslint-disable-next-line object-curly-newline
import { mdiArrowLeftThinCircleOutline, mdiArrowRightThinCircleOutline } from '@mdi/js'

export default {
    props: {
        header: {
            type: Object,
            default: () => {}
        }
    },
    data() {
        return {
            icons: { mdiArrowLeftThinCircleOutline, mdiArrowRightThinCircleOutline }
        }
    },
    methods: {
        slidePrev(){},
        slideNext(){}
    },
}
</script>

<style lang="scss">
</style>