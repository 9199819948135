<template>
  <v-card class="mx-auto rounded-lg" outlined>
    <v-container>
      <v-row>
        <v-col cols="12" class="d-flex flex-row align-centr">
          <div class="mx-4 d-bloc">
            <h4>
              <span class="secondary--text">{{
                  this.$store.getters.getCompany.name
              }}</span>
            </h4>
            <span>{{ service.name }}</span>
          </div>
          <v-spacer></v-spacer>
          <span>{{ service.domain }}</span>
        </v-col>

        <v-col cols="12" class="pt-0">
            <v-card-title class="subaccent">{{ service.title }} </v-card-title>

            <v-card-text v-html="service.description" class="pt-2">

            </v-card-text>
          <v-divider></v-divider>
        </v-col>
      </v-row>
    </v-container>
    <v-card-actions>
      <v-btn color="secondary" class="text-capitalize subsecondary px-3" text @click="showImages" elevation="">
        {{ show ? 'Hide' : 'View' }} related images
      </v-btn>
      <v-spacer></v-spacer>
      <v-btn icon @click="showImages">
        <v-icon>{{ show ? icons.mdiChevronUp : icons.mdiChevronDown }}</v-icon>
      </v-btn>
    </v-card-actions>
    <v-expand-transition>
      <div v-show="show">
        <v-divider></v-divider>
        <v-container fluid>
          <v-row dense>
          <v-col cols="12" v-if="this.servicesImages.length <= 0" class="text-center">This service has no image..!</v-col>
            <v-col v-for="(image, n) in this.servicesImages" :key="n" cols="6" md="4">
              <v-card flat tile outlined class="rounded-lg">
                <v-img :src="url_img + image.path + image.name" :lazy-src="url_img + image.path + image.name"
                  aspect-ratio="1" gradient="to bottom, rgba(0,0,0,.1), rgba(0,0,0,.5)" height="12em"
                  class="secondary lighten-2">
                  <template v-slot:placeholder>
                    <v-row class="fill-height ma-0" align="center" justify="center">
                      <v-progress-circular indeterminate color="secondary lighten-5">
                      </v-progress-circular>
                    </v-row>
                  </template>
                  <v-card-subtitle class="py-2 subsecondary"> Details: {{ image.details }}</v-card-subtitle>
                </v-img>
              </v-card>
            </v-col>
          </v-row>
        </v-container>
      </div>
    </v-expand-transition>
  </v-card>
</template>

<script>
import { mdiChevronUp, mdiChevronDown, mdiShareVariant, mdiBookmark, mdiHeart } from '@mdi/js';
import { Drequest } from '@/plugins/Drequest'
export default {
  props: {
    service: {
      type: Object,
      default: () => { }
    }
  },
  data: () => ({
    show: false,
    loadingImage: true,
    isImagesLoaded: false,
    servicesImages: [],
    icons: { mdiChevronUp, mdiChevronDown, mdiShareVariant, mdiBookmark, mdiHeart }
  }),
  methods: {

    showImages() {
      if ((this.servicesImages.length <= 0) && (this.isImagesLoaded === false)) {
        if (this.show === false) {
          this.loadServicesImages()
        }
      }
      this.show = !this.show
    },
    loadServicesImages() {
      this.loadingImage = true
      Drequest.api(`lazyloading.serviceimage?dfilters=on&service_id:eq=${this.service.id}`)
        .get((response) => {
          if (response.success === true) {
            response.listEntity.forEach((value, index) => {
              this.servicesImages.push({
                name: value.name,
                path: value.path + '/',
                details: value.description,
              })
            })
            this.loadingImage = false
            this.isImagesLoaded = true
          } else {
            this.$fire({
              title: "Erreur inattendue!",
              type: "error",
              text: "Veuillez recharger la page..!",
              timer: 3000
            })
            this.loadingImage = false
          }
        })
        .catch((err) => {
          console.log("err")
          this.loadingImage = false
        })
    },
  },
  setup() {
    const url_img = Drequest.__envimg
    return {
      url_img
    }
  }
}
</script>