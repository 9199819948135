<template>
    <!-- <header-page :header="headers"></header-page> -->
    <v-container>
        <v-row class="">
            <!-- <v-col sm="10" md="3"></v-col> -->
            <!-- <search-proposals :header="headersSearch" :loader="loader.loadSearchResult" :result="searchDetails"
                        @close="closeSearch" @action="searchActions">
                    </search-proposals> -->
            <v-row class="mx-auto" v-if="loader.loaderdata === false && no_resultitem <= 0">
                <empty-data :text="nodatas"></empty-data>
            </v-row>
            <v-row class="px-2 py-4 mx-auto" v-else>
                <v-col class="mx-auto">
                    <v-card elevation="0" class="mx-auto" flat tile v-if="loader.loaderdata === false">
                        <div v-for="(item, ind) in vacanciesItems" :key="ind">
                            <vacancies-item-card :item="item"></vacancies-item-card>
                            <v-divider></v-divider>
                        </div>
                    </v-card>
                    <template v-else>
                        <v-skeleton-loader class="mx-auto pb-2" max-width="100%" type="list-item-two-line"
                            v-for="(i, j) in 5" :key="j">
                        </v-skeleton-loader>
                    </template>
                </v-col>
            </v-row>
        </v-row>
    </v-container>
</template>
<script>
// mdiVideoInputComponent
import { mdiMagnify, mdiMapMarkerOutline, mdiClose, mdiHelpCircleOutline, mdiCalendar, mdiKeyboardBackspace, mdiAlertOutline, mdiBriefcase, mdiOpenInNew } from '@mdi/js'
import { Drequest } from '@/plugins/Drequest'
import { Utils } from '@/plugins/Utils';
import HeaderSearchPage from '@/views/components/HeaderSearchPage.vue'
import HeaderPage from '@/views/pages/components/HeaderPage.vue'
import VacanciesItemCard from '@/views/components/vacancies/VacanciesItemCard.vue';
import SearchProposals from '@/views/pages/company/components/SearchProposals.vue'
import EmptyData from '@/views/components/utils/EmptyData.vue';
export default {
    components: {
        HeaderSearchPage,
        HeaderPage,
        VacanciesItemCard,
        SearchProposals,
        EmptyData
    },
    data() {
        return {
            no_resultitem: 0,
            searchDetails: '',
            loader: {
                loadSearchResult: false,
                loaderdata: false
            },
            nodatas: 'vacancies',
            submit: true,
            jobitem: {},
            vacancieslist: [],
            headersSearch: {
                title: "Vacancies",
                subtitle: "",
                icon: '',
                search: {
                    first: {
                        text: 'Search by offer',
                        icon: mdiMagnify
                    },
                    second: {
                        text: 'Search by place',
                        icon: mdiMapMarkerOutline
                    },
                }
            },
            headers: {
                titlepage: 'More vacancies',
                icon: this.icons.mdiMagnify
            }
        }
    },
    computed: {
        vacanciesItems() {
            return this.vacancieslist
        },
    },
    methods: {
        closeSearch() {
            this.loader.loaderdata = true
            this.vacancieslist = []
            this.loadInitialJob()
        },
        searchActions(data) {
            this.loader.loadSearchResult = true
            this.loader.loaderdata = true
            const mainInputText = data.title.isEmpty === false ? data.title.value : ''
            const subInputText = data.subtitle.isEmpty === false ? data.subtitle.value : ''
            const domainInputtext = data.domain.isEmpty === false ? data.domain.value : ''

            this.vacancieslist = []
            Drequest.api(`job.search?offer_title=${mainInputText}&joblocation=${subInputText}`)
                .get((response) => {
                    if (response.success === true) {
                        response.listEntity.forEach((value, index) => {
                            this.vacancieslist.push(value)
                        });
                        this.jobitem = response.listEntity[0]
                        this.searchDetails = response.detail
                        this.no_resultitem = response.nb_element
                        this.loader.loadSearchResult = false
                        this.loader.loaderdata = false
                    }
                    else {
                        this.loader.loadSearchResult = false
                        this.searchDetails = response.detail
                        this.loader.loaderdata = false
                        this.no_resultitem = 0
                    }
                })
                .catch((err) => {
                    this.loader.loadSearchResult = false
                    this.loader.loaderdata = false
                    this.no_resultitem = 0
                });
        },
        async selectedjob(value) {
            this.$router.push({ name: 'vacancies.details', params: { offer_id: value.id, offer_title: Utils.replaceAll(value.title.trim(), ' ', '-') } })
            this.jobitem = value
        },
        async loadInitialJob() {
            this.loader.loaderdata = true
            Drequest.api(`lazyloading.job?dfilters=on&enterprise.id:eq=${this.$route.params.ids}&next=1&per_page=15`)
                .get((response) => {
                    if (response.success === true) {
                        response.listEntity.forEach((value, index) => {
                            this.vacancieslist.push(value)
                        });
                        this.loader.loaderdata = false
                        this.no_resultitem = this.vacancieslist.length
                    }
                    else {
                        this.loader.loaderdata = false
                        this.no_resultitem = 0
                    }
                })
                .catch((err) => {
                    this.loader.loaderdata = false
                    this.no_resultitem = 0
                });
        }
    },
    beforeMount() {
        this.loadInitialJob()
    },
    setup(props) {
        const icons = { mdiClose, mdiHelpCircleOutline, mdiCalendar, mdiKeyboardBackspace, mdiAlertOutline, mdiBriefcase, mdiOpenInNew }
        return {
            icons
        }
    }
}
</script>