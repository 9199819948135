<template>
    <div class="ma-4">
        <div class="">
            <h1 class="primary--text">
                {{ header.title }}
            </h1>
            <span class="">
                -- {{ header.subtitle }}
            </span>
        </div>
        <div class="my-10"></div>
        <div class="d-flex flex-row">
            <div class="d-flex flex-row w-full">
                <!-- {{inputTitle}} -->
                <v-text-field v-model="inputTitle" :prepend-inner-icon="header.search.first.icon"
                    :placeholder="header.search.first.text" solo hide-details outlined dense flat clearable>
                </v-text-field>
            </div>
            <div class="ml-4">
                <v-btn tile color="primary" class="search-field-btn rounded" @click="actionSearchBtn"
                    :loading="loadingSearch">
                    <v-icon>
                        {{ icons.mdiMagnify }}</v-icon>
                    Search
                </v-btn>
            </div>
        </div>

        <span class="error--text text-caption">{{ emptyFieldData }}</span>
        <!--  -->
        <div class="d-flex flex-row" v-if="isBtnResultVisible === true">
            <span>{{ searchDetails }}</span>
            <v-spacer></v-spacer>
            <!-- :disabled="(!inputTitle && !inputSubtitle && showdomain === false)? true: false"  -->
            <v-btn small text color="error" class="lightError" @click="closeSearchResult" :disabled="false">
                <v-icon>{{ icons.mdiClose }}</v-icon>
                <span>close result</span>
            </v-btn>
        </div>
    </div>
</template>

<script>
import { mdiDomain, mdiClose, mdiAccountNetwork, mdiMagnify, mdiMapMarkerOutline } from '@mdi/js';
import { Drequest } from '@/plugins/Drequest'
export default {
    props: {
        header: {
            type: Object,
            default: () => { }
        },
        loader: {
            type: Boolean,
            default: false
        },
        result: {
            type: String,
            default: ''
        }
    },
    data() {
        return {
            isBtnResultVisible: false,
            isReadyToSearch: false,
            emptyFieldData: '',
            showdomain: false,
            inputTitle: "",
            inputSubtitle: "",

            selectedsector: "",
            listsector: [],
            loadingData: true,

            inputDomain: "Business Et Entreprenariat",
            icons: { mdiDomain, mdiClose, mdiAccountNetwork, mdiMagnify, mdiMapMarkerOutline },
            searchData: {
                title: {
                    isEmpty: true,
                    value: ''
                },
                subtitle: {
                    isEmpty: true,
                    value: ''
                },
                domain: {
                    isEmpty: !this.showdomain,
                    value: ''
                },
            }
        }
    },
    computed: {
        loadingSearch() {
            return this.loader
        },
        searchDetails() {
            return this.result
        }
    },
    methods: {
        checkInputTitle() {
            if (this.inputTitle) {
                if (this.inputTitle.trim()) {
                    this.searchData.title.isEmpty = false
                    this.searchData.title.value = this.inputTitle
                    this.readyToSearch = true
                }
                else {
                    this.searchData.title.isEmpty = true
                }
            }
            else {
                this.searchData.title.isEmpty = true
            }
        },

        checkInputSubtitle() {
            if (this.inputSubtitle) {
                if (this.inputSubtitle.trim()) {
                    this.searchData.subtitle.isEmpty = false
                    this.searchData.subtitle.value = this.inputSubtitle
                    this.readyToSearch = true
                }
                else {
                    this.searchData.subtitle.isEmpty = true
                }
            }
            else {
                this.searchData.subtitle.isEmpty = true
            }
        },
        actionSearchBtn() {
            this.checkInputTitle()
            this.checkInputSubtitle()

            this.isBtnResultVisible = true
            this.emptyFieldData = ''

            this.$emit('action', this.searchData)
        },
        closeSearchResult() {
            this.$emit('close', null)
            this.isBtnResultVisible = false

            this.showdomain = false
            this.inputSubtitle = ''
            this.inputTitle = ''
        },
    },
    setup(props) {
        return {}
    }
}
</script>

<style lang="scss" scoped>
//@import '@/styles/styles.scss';
</style>
