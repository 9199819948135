<template>
    <div class="mx-auto" flat>
        <!-- <title-item :header="headers"></title-item> -->
        <v-container style="height: 400px;" class="mx-auto" v-if="loadingData === true">
            <v-row class="fill-height" align-content="center" justify="center">
                <v-col class="text-subtitle-1 text-center mx-auto" cols="8">
                    Loading available services and products
                </v-col>
                <v-col cols="5" class="mx-auto">
                    <v-progress-linear color="secondary" indeterminate rounded height="6">
                    </v-progress-linear>
                </v-col>
            </v-row>
        </v-container>
        <v-container v-else>
            <div v-if="datafound">
                <service-item v-for="(item, id) in servicesDatas" :service="item" :key="id" class="ma-4">
                </service-item>
            </div>
            <empty-data :text="nodatas" v-else></empty-data>
        </v-container>
    </div>
</template>

<script>
import ServiceItem from '@/views/pages/company/components/ServiceItem.vue';
import TitleItem from '@/views/pages/components/TitleItem.vue';
import { Drequest } from '@/plugins/Drequest'
import EmptyData from '@/views/components/utils/EmptyData.vue';
export default {
    data: () => ({
        loadingData: true,
        servicesDatas: [],
        datafound: true,
        headers: { title: 'Services and products', subtitle: 'Browse compannies services and products' },
        nodatas: 'services  or product'
    
    }),
    components: {
        ServiceItem,
        TitleItem,
        EmptyData

    },
    beforeMount() {
        this.loadingData = true
        Drequest.api(`lazyloading.service?dfilters=on&service.enterprise_id:eq=${this.$store.getters.getService.id}`)
            .get((response) => {
                if (response.success === true) {
                    response.listEntity.forEach((element, value) => {
                        if (element.status === '1') {
                            this.servicesDatas.push({
                                "id": element.id,
                                "name": element.name,
                                "title": element.title,
                                "description": element.description,
                                "domain": element.domain,
                                "status": element.status,
                                "enterprise": element.enterprise,
                            })
                        }
                    });
                    this.loadingData = false
                    if (this.servicesDatas.length > 0) {
                        this.datafound = true
                    }
                    else {
                        this.datafound = false
                    }
                } else {
                    this.$fire({
                        title: "Erreur inattendue!",
                        type: "error",
                        text: "Veuillez recharger la page..!",
                        timer: 3000
                    })
                    this.loadingData = false

                }
            })
            .catch((err) => {
                console.log("err")

                this.loadingData = false
            })
    },
}
</script>