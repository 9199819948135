<template>
    <!-- <div > -->
    <v-container class="col-md-10 mx-auto">
        <v-card class="mb-4 " v-if="loader.company == false">
            <div class="com-head-profile">
                <div class="com-head-cover">
                </div>
                <div class="com-head-logo">
                    <v-avatar size="100%" rounded="0">
                        <!-- Company logo here -->
                        <v-img :src="require('@/assets/images/avatars/img-xl.svg')" alt="logo-user">
                        </v-img>
                    </v-avatar>
                </div>
            </div>
            <div class="px-4">
                <div class="headline">
                    {{ company.name }}
                </div>
                <div>
                    <!-- {{comapny.activity_sector}} -->
                    {{ company.about }}
                </div>
                <div class="d-flex flex-row">
                    <v-icon small>
                        {{ icons.mdiMapMarkerOutline }}
                    </v-icon>
                    <span class="text-caption"> {{ company.country.name }}</span>
                </div>
            </div>
            <v-card-actions>
            </v-card-actions>

            <v-tabs v-model="tabe" hide-slider elevation="0" class="rounded-lg" flat>
                <v-tab v-for="(tab, index) in tabs" :key="index" class="py-4" active-class='primaryAccent'>
                    <span class="text-caption">{{ tab }}</span>
                </v-tab>
            </v-tabs>
        </v-card>
        <v-card v-else>
            <v-col cols="12" class="mb-2">
                <v-skeleton-loader class="mx-auto border" type="card-avatar, list-item-two-line">
                </v-skeleton-loader>
            </v-col>
        </v-card>
        <v-tabs-items v-model="tabe" class="rounded-lg pa-0" v-if="loader.company == false">
            <v-tab-item :transition="false">
                <publication-view></publication-view>
            </v-tab-item>
            <v-tab-item :transition="false">
                <vacancies></vacancies>
            </v-tab-item>

            <v-tab-item :transition="false">
                <services-view></services-view>
            </v-tab-item>

            <v-tab-item :transition="false">
                <company-infos></company-infos>
            </v-tab-item>

            <v-tab-item :transition="false">
                <blogue-company></blogue-company>
            </v-tab-item>
        </v-tabs-items>
        <v-template v-else>
            <v-card v-for="i in 2" :key="i" elevation="0">
                <v-col cols="12" class="mt-2">
                    <v-skeleton-loader class="mx-auto border" type="list-item-two-line"></v-skeleton-loader>
                </v-col>
            </v-card></v-template>
    </v-container>
    <!-- </div> -->
</template>


<script>
// eslint-disable-next-line object-curly-newline
import { mdiMapMarkerOutline, mdiWeb, mdiArrowLeft } from '@mdi/js'
import CompanyInfos from '@/views/pages/company/components/CompanyInfos.vue'
import ServicesView from '@/views/pages/company/ServicesView.vue'
import PublicationView from '@/views/pages/company/PublicationView.vue'
import Vacancies from '@/views/pages/company/Vacancies.vue'
import BlogueCompany from '@/views/pages/company/BlogueCompany.vue'
import { Drequest } from '@/plugins/Drequest'
export default {
    components: {
        CompanyInfos,
        ServicesView,
        PublicationView,
        BlogueCompany,
        Vacancies
    },
    data() {
        return {
            company: {},
            tabe: '',
            tabs: ['Calls for proposals', 'Vacancies', 'Services & Products', 'About', 'Blog'],
            loader: {
                company: true
            }
        }
    },
    beforeMount() {
        // watch the params of the route to fetch the data again
        this.$watch(
            () => this.$route.params,
            () => {
                this.fetchData()
            },
            { immediate: true }
        )
    },
    methods: {
        fetchData() {
            this.loader.company = true
            Drequest.api(`lazyloading.enterprise?dfilters=on&enterprise.id:eq=${this.$route.params.ids}&next=1&per_page=1`)
                .get((response) => {
                    if (response.success === true) {
                        this.company = response.listEntity[0]
                        // console.log(this.company)
                        this.loader.company = false
                    }
                    else {
                        this.loader.company = false
                    }
                })
                .catch((err) => {
                    this.loader.company = false
                });
        },
    },
    setup() {
        const icons = {
            mdiMapMarkerOutline, mdiWeb, mdiArrowLeft
        }
        return {
            icons
        }
    },
}
</script>

<style lang="scss">
@import '@/styles/tabs.scss';

.com-head-profile {
    position: relative;
    //height: calc(5 * 3em);
    margin-bottom: calc(3.5em + 1em); // avatar size + card margin
    //border-radius: 1em !important;

    .com-head-cover {
        position: relative;
        height: 7em !important;
        //border-radius: 1em !important;
        background: -webkit-linear-gradient(90deg, var(--v-primary-base) -30%, rgba(72, 149, 236, 0.651) 100%);
    }

    .com-head-logo {
        height: 7em;
        width: 7em;
        bottom: calc(-3.5em + 3px); // [height/2] + [border/2]
        left: 1em;
        top: 3.5em;
        position: absolute;
        z-index: 4;
    }
}
</style>
