<template>
  <div class="">
    <v-container>
      <!-- <search-proposals :header="headersSearch" :loader="loadSearchResult" :result="searchDetails"
        @close="closeSearch" @action="searchActions">
      </search-proposals> -->

      <v-row class="mx-auto mt-3" v-if="loaderdata === false && no_resultitem <= 0">
        <empty-data :text="nodatas"></empty-data>
      </v-row>

      <v-row class="px-2 py-4 mx-auto" v-else>
        <!-- Proposal's list -->

        <v-col class="mx-auto">
          <div v-if="loaderdata === false">
            <proposal-item-card v-for="(item, i) in proposalItems" :reoi="item" :key="i" class="pb-2">
            </proposal-item-card>
          </div>
          <v-skeleton-loader v-for="i in 5" :key="i" class="mx-auto" max-width="100%" type="article, card" v-else>
          </v-skeleton-loader>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>
<script>
// mdiVideoInputComponent
import { mdiClose, mdiMagnify, mdiDomain, mdiHelpCircleOutline, mdiCalendar, mdiKeyboardBackspace, mdiAlertOutline, mdiBriefcase, mdiMapMarkerOutline, mdiOpenInNew } from '@mdi/js'
import { Drequest } from '@/plugins/Drequest'
import { Utils } from '@/plugins/Utils';
import CheckRoleDialog from '@/views/components/CheckRoleDialog.vue';
import SlideComponent from '@/views/pages/components/SlideComponent.vue'
import SearchProposals from '@/views/pages/company/components/SearchProposals.vue'
import HeaderPage from '@/views/pages/components/HeaderPage.vue'
import ProposalItemCard from '@/views/components/proposals/ProposalItemCard.vue';
import EmptyData from '@/views/components/utils/EmptyData.vue';


export default {
  components: {
    CheckRoleDialog,
    SearchProposals,
    HeaderPage,
    SlideComponent,
    ProposalItemCard,
    EmptyData
  },
  data() {
    return {

      no_resultitem: 0,
      searchDetails: '',

      loadSearchResult: false,
      loaderdata: false,
        
      nodatas: 'call for proposal',

      submit: true,
      absolute: true,
      overlay: false,
      reoiP: {},
      headersSearch: {
        title: "Calls for proposals",
        subtitle: "",
        icon: '',
        search: {
          first: {
            text: 'Search by proposal',
            icon: mdiMagnify
          },
          second: {
            text: 'Search by company',
            icon: mdiDomain
          },
        }
      },
      proposalslist: [],
      loadingRfpEnterprise: false
    }
  },
  computed: {
    proposalItems() {
      return this.proposalslist
    },
    length() {
      return 7000
    },
  },
  methods: {

    closeSearch() {
      this.loaderdata = true
      this.vacancieslist = []
      this.loadInitialProposals()
    },

    searchActions(data) {
      this.loadSearchResult = true
      this.loaderdata = true
      const mainInputText = data.title.isEmpty === false ? data.title.value : ''
      const subInputText = data.subtitle.isEmpty === false ? data.subtitle.value : ''
      const domainInputtext = data.domain.isEmpty === false ? data.domain.value : ''

      this.proposalslist = []
      Drequest.api(`rfp.search?rfptitle=${mainInputText}&rfpcompany=${subInputText}`)
        .get((response) => {
          if (response.success === true) {
            response.listEntity.forEach((element, index) => {
              this.proposalslist.push(element)

            });
            this.reoiP = response.listEntity[0]
            this.searchDetails = response.detail
            this.no_resultitem = response.nb_element
            this.loadSearchResult = false
            this.loaderdata = false
          }
          else {
            this.loadSearchResult = false
            this.searchDetails = response.detail
            this.loaderdata = false
            this.no_resultitem = 0
          }

        })
        .catch((err) => {
          this.loadSearchResult = false
          this.loaderdata = false

          this.no_resultitem = 0
        });
    },

    selectedCallForProposal(value) {
      this.$router.push({ name: 'proposal.details', params: { offer_id: value.id, offer_title: Utils.replaceAll(value.title, ' ', '-') } })
      this.reoiP = value
    },
    loadInitialProposals() {
      Drequest.api(`lazyloading.rfp?dfilters=on&enterprise.id:eq=${this.$route.params.ids}&next=1&per_page=15`)
        .get((response) => {
          if (response.success === true) {
            response.listEntity.forEach((element, index) => {

              this.proposalslist.push(element)
            });
            this.loaderdata = false
            this.no_resultitem = this.proposalslist.length
          }
          else {
            this.loaderdata = false
            this.no_resultitem = 0
          }
        })
        .catch((err) => {
          this.loaderdata = false
          this.loaderdata = false
        });
    }
  },
  beforeMount() {
    this.loadInitialProposals()
  },
  setup(props) {
    const icons = { mdiClose, mdiHelpCircleOutline, mdiCalendar, mdiKeyboardBackspace, mdiAlertOutline, mdiBriefcase, mdiDomain, mdiMapMarkerOutline, mdiOpenInNew }

    return {
      icons
    }
  }
}
</script>